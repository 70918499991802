import {createSlice} from '@reduxjs/toolkit';
import {MODE} from "../../constants/settings/modes";

const darkMode = createSlice({
    name: 'darkMode',
    initialState: { mode: MODE.SYSTEM_PREF },
    reducers: {
        store(state, data) {
            state.mode = data.payload;
        }
    }
});

export const darkModeActions = darkMode.actions;
export default darkMode;