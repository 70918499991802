const sk = {
    MONDAY: 'Pondelok',
    TUESDAY: 'Utorok',
    WEDNESDAY: 'Streda',
    THURSDAY: 'Štvrtok',
    FRIDAY: 'Piatok',
    SATURDAY: 'Sobota',
    SUNDAY: 'Nedela',
    SIGN_IN: 'Prihlásiť sa',
    OR: 'Alebo',
    SIGN_UP: 'Vytvoriť účet',
    NEW_USER: 'Nový užívateľ?',
    CONTINUE_WITH: 'Pokračovať s',
    APPLE: 'Apple',
    GOOGLE: 'Google',
    FACEBOOK: 'Facebook',
    GITHUB: 'GitHub',
    SIGN_IN_SUCCESSFUL: 'Boli ste úspešne prihlásený.',
    SING_UP_SUCCESSFUL: 'Boli ste úspešne zaregistrovaný.',
    SIGN_OUT_SUCCESSFUL: 'Boli ste úspešne odhlásený.',
    BASIC_ERROR: 'Chyba! Niečo sa pokazilo, prosím skúste znovu.',
    EMAIL: 'Email',
    PASSWORD: 'Heslo',
    HAVE_ACCOUNT: 'Máte už účet?',
    FULL_NAME: 'Celé meno',
    OPENING_HOURS: 'Otváracia doba',
    VOUCHER: 'Darčekový kupón',
    IDENTITY: 'Identifikačný kód',
    CHECK_VALIDITY: 'Skontrolovať platnosť',
    SETTINGS: 'Nastavenia',
    ADMIN: 'Admin',
    USER: 'Jazdec',
    EMPLOYEE: 'Zamestnanec',
    HOMEPAGE: 'Domov',
    CONTACT: 'Kontakt',
    PRICE_LIST: 'Cenník',
    GALLERY: 'Fotogaléria',
    PROFILE: 'Profil',
    ADMINISTRATOR: 'Administrátor',
    RACER: 'Pretekár',
    LANGUAGE: 'Jazyk',
    DARK_MODE: 'Tmavý režim',
    LIGHT_MODE: 'Svetlý režim',
    SYSTEM_PREF: 'Automaticky',
    COLOR_SCHEME: 'Farebná schéma',
    INSTAGRAM_MEDIA: 'Náš Instagram',
    INSTAGRAM_TOP: 'Príspevky fanúšikov',
    LIKE: 'človeku sa to páči',
    LIKES: 'ľudom sa to páči',
    SET_SCHEDULE: 'Otváracia doba',
    PREVIEW: 'Náhľad',
    CONTENT: 'Obsah',
    NEW_VALUES: 'Nové hodnoty',
    DAY: 'Deň',
    OPEN_FROM: 'OD',
    CLOSED_FROM: 'DO',
    CLOSED: 'Zatvorené',
    TABLE_ACTIONS: 'Akcie',
    SAVE: 'Uložiť',
    OPENING_HOURS_SUCCESSFUL_CHANGE: 'Otváracie hodiny boli úspešne zmenené.',
    CATEGORY_TABLE: 'Kategória',
    CATEGORY: 'Kategórie',
    SUBCATEGORY_TABLE: 'Podkategória',
    SUBCATEGORY: 'Podkategórie',
    ARTICLE: 'Položky',
    ARTICLE_TABLE: 'Položka',
    PRICE_TABLE: 'Cenník',
    ADD_PRICE_TABLE: 'Pridať nový cenník',
    ALL_CATEGORY: 'Všetky kategórie',
    ALL_SUBCATEGORY: 'Všetky podkategórie',
    ALL_ARTICLES: 'Všetky položky',
    ADD_CATEGORY: 'Pridať novú kategóriu',
    ADD_SUBCATEGORY: 'Pridať novú podkategóriu',
    ADD_ARTICLES: 'Pridať novú položku',
    ADD_ALERT: 'Pridať nové upozornenie',
    EDIT_ALERT: 'Upraviť existujúce upozornenie',
    ICON: 'Ikona',
    TITLE: 'Názov',
    DESCRIPTION: 'Popis',
    ALERTS: 'Upozornenia',
    SEVERITY: 'Závažnosť',
    INFO: 'Info',
    ERROR: 'Chyba',
    WARNING: 'Upozornenie',
    SUCCESS: 'Úspech',
    VARIANT: 'Varianta',
    OUTLINED: 'Obrys',
    FILLED: 'Plné',
    CLASSIC: 'Klasické',
    MESSAGE: 'Správa',
    CANCEL: 'Zrušiť',
    ADD: 'Pridať',
    EDIT: 'Upraviť',
    REQUIRED_FIELD: 'Toto pole je povinné!',
    SELECT_ICON: 'Vybrať novú ikonu',
    SELECTED_ICON: 'Vybraná ikona',
    CHANGE_ICON: 'Zmeniť ikonu',
    CHOOSE: 'Vyberte',
    ADD_CATEGORY_SUCCESSFUL: 'Kategória bola úspešne vytvorená.',
    CHANGE_CATEGORY_SUCCESSFUL: 'Kategória bola úspešne upravená.',
    DELETE_CATEGORY_SUCCESSFUL: 'Kategória bola úspešne odstránená.',
    ADD_SUBCATEGORY_SUCCESSFUL: 'Podkategória bola úspešne vytvorená.',
    CHANGE_SUBCATEGORY_SUCCESSFUL: 'Podkategória bola úspešne upravená.',
    DELETE_SUBCATEGORY_SUCCESSFUL: 'Podkategória bola úspešne odstránená.',
    ADD_ARTICLE_SUCCESSFUL: 'Položka bola úspešne vytvorená.',
    CHANGE_ARTICLE_SUCCESSFUL: 'Položka bola úspešne upravená.',
    DELETE_ARTICLE_SUCCESSFUL: 'Položka bola úspešne odstránená.',
    VOLUME: 'Množstvo',
    PRICE: 'Cena',
    ADD_NEW_ROW: 'Pridať riadok',
    REMOVE_COLUMN: 'Odstrániť stĺpec',
    REMOVE_ROW: 'Odstrániť riadok',
    ADD_NEW_COLUMN: 'Pridať stĺpec',
    EDIT_TITLE: 'Upraviť text',
    CONFIRM: 'Potvrdiť',
    EVENT: 'Udalosť',
    DATE_TIME: 'Dátum a čas',
    USER_GENERAL: 'Užívateľ',
    VOUCHER_CREATE: 'Vytvorenie darčekovej poukážky',
    VOUCHER_ACTIVATE: 'Prijatie platby za darčekovú poukážku a jej aktivácia',
    VOUCHER_USE: 'Darčekový kupón bol použitý',
    VOUCHER_REACHED_CAPACITY: 'Maximálny počet jázd bol dosiahnutý',
    VOUCHER_NOT_PAID: 'Darčekovému kupónu vypršala splatnosť na zaplatenie',
    VOUCHER_TERMINATED: 'Darčekový kupón bol ukončený',
    HISTORY: 'História',
    STATUS: 'Stav',
    RIDES_TOTAL: 'Celkový počet jázd',
    RIDES_USED: 'Počet použitých jázd',
    PAYMENT_REQUIRED: 'Čaká na zaplatenie',
    ACTIVE: 'Aktívny',
    REACHED_CAPACITY: 'Vyčerpaný',
    OVERDUE: 'Po splatnosti',
    TERMINATED: 'Ukončený',
    VOUCHER_LIST: 'Zoznam darčekových kupónov',
    USER_LIST: 'Zoznam užívateľov',
    ACTIVATE: 'Aktivovať',
    TERMINATE: 'Zrušiť',
    FILTER: 'Filter',
    FILTER_ACTION: 'Filtrovať tabulku',
    ESHOP: 'Obchod',
    CREATE_VOUCHER_OPTION: 'Vytvoriť novú variantu kupónu',
    CREATE: 'Vytvoriť',
    VOUCHER_OPTIONS: 'Nastavenia kupónov',
    RIDES_NUMBER: 'Počet jázd',
    DELETE: 'Zmazať',
    WELCOME_POSTER: 'Darujte darček plný adrenalínu',
    WELCOME_POSTER_SUBTITLE: 'Hľadáte originálny spôsob, ako prekvapiť svojich rodičov, potešiť svojho partnera alebo zdieľať úsmev so svojimi priateľmi, sme tu pre vás. Prekvapte svoju rodinu, kamarátov a možno aj sami seba tým, že im doprajete nezabudnuteľné chvíle a zážitky, ktoré si budú pamätať ešte dlho. Vyberte si zo širokej ponuky našich darčekových kúpónov a spravte radosť tým najdôležitejším ľuďom vo svojom živote.',
    SELECT_VOUCHER_OPTION: 'Vyberte variantu',
    FILL_INFO: 'Vyplňte kontaktné údaje',
    COLLECT_VOUCHER: 'Vyzdvihnite si váš kupón',
    SINGLE_RIDE: 'jazda',
    SMALL_AMOUNT_RIDES: 'jazdy',
    BIG_AMOUNT_RIDES: 'jázd',
    VOUCHER_STATISTICS: 'Štatistika použitia kupónov'
};

export default sk;