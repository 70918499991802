import React from 'react';
import {Box, Typography} from '@mui/material';
import NavGroup from './NavGroup';
import menuItem from '../../../constants/menu'

export default function MenuList() {
    return (
        <Box>
            {
                menuItem.items.map((item) => {
                    switch (item.type) {
                        case 'group':
                            return (
                                <NavGroup key={item.id} item={item}/>
                            )
                        default:
                            return (
                                <Typography key={item.id} variant="h6" color="error" align="center">
                                    Menu Items Error
                                </Typography>
                            )

                    }
                })
            }
        </Box>
    );
}
