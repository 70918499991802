import {createSlice} from '@reduxjs/toolkit';
import {LANGUAGE} from "../../constants/localization/languages";

const localizationSlice = createSlice({
    name: 'localization',
    initialState: {locale: LANGUAGE.SLOVAK},
    reducers: {
        store(state, data) {
            state.locale = data.payload;
        },
    }
});

export const localizationActions = localizationSlice.actions;
export default localizationSlice;