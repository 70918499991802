import React, {useState} from 'react';
import {ListItemButton, ListItemIcon, ListItemText, Collapse, Box, Stack, Grid, IconButton} from '@mui/material';
import {alpha, useTheme, styled} from '@mui/material/styles';
import DisplaySettingsTwoToneIcon from '@mui/icons-material/DisplaySettingsTwoTone';
import DarkModeTwoToneIcon from '@mui/icons-material/DarkModeTwoTone';
import ColorLensTwoToneIcon from '@mui/icons-material/ColorLensTwoTone';
import CheckIcon from '@mui/icons-material/Check';
import {useDispatch, useSelector} from "react-redux";
import {localizationActions} from "../../store/settings/localization-slice";
import {darkModeActions} from "../../store/settings/dark-mode-slice";
import {PREFERRED_COLOR_SCHEME, PREFERRED_LANGUAGE, PREFERRED_MODE} from "../../constants";
import {AVAILABLE_LANGUAGES} from "../../constants/localization/languages";
import {AVAILABLE_MODES, MODE_ICONS} from "../../constants/settings/modes";
import {AVAILABLE_COLOR_SCHEMES} from "../../constants/settings/colorSchemes";
import {colorSchemeActions} from "../../store/settings/color-scheme-slice";

export default function Settings() {

    const [settings, setSettings] = useState(false);
    const [language, setLanguage] = useState(false);
    const [darkMode, setDarkMode] = useState(false);
    const [colorScheme, setColorScheme] = useState(false);

    const ListItemIconStyle = styled(ListItemIcon)({
        width: 22,
        height: 22,
        color: 'inherit',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    });


    const color = useSelector(state => state.colorScheme.scheme);
    const locale = useSelector(state => state.localization.locale);
    const mode = useSelector(state => state.darkMode.mode);
    const localization = locale.locale;

    const theme = useTheme();

    function buttonStyle(level, open) {
        if (open) {
            return {
                color: 'primary.main',
                fontWeight: 'fontWeightMedium',
                ...theme.typography.body2,
                height: 48,
                backgroundColor: alpha(theme.palette.primary.main, 0.10),
                borderRadius: theme.shape.borderRadius,
            }
        } else {
            return {
                ...theme.typography.body2,
                height: 48,
                color: theme.palette.text.secondary,
                borderRadius: theme.shape.borderRadius,
            }
        }
    }

    function groupStyle(level, open) {
        if (open) {
            return {
                backgroundColor: level === 0 ? alpha(theme.palette.primary.main, 0.10) : (level === 1 ? alpha(theme.palette.primary.main, 0.05) : alpha(theme.palette.primary.main, 0.025)),
                borderRadius: theme.shape.borderRadius
            }
        } else {
            return {}
        }
    }

    function handleSettings() {
        setSettings(!settings);
    }

    function handleLanguage() {
        setLanguage(!language);
    }

    function handleDarkMode() {
        setDarkMode(!darkMode);
    }

    function handleColorScheme() {
        setColorScheme(!colorScheme);
    }

    const dispatch = useDispatch();

    function setLanguageOption(option) {
        if (AVAILABLE_LANGUAGES.includes(option)) {
            dispatch(localizationActions.store(option));
            localStorage.setItem(PREFERRED_LANGUAGE, option.value);
        }
    }

    function setMode(option) {
        if (AVAILABLE_MODES.includes(option)) {
            dispatch(darkModeActions.store(option))
            localStorage.setItem(PREFERRED_MODE, option.value);
        }
    }

    function getModeIcon(option) {
        let Icon = MODE_ICONS[option.icon];
        return (<Icon />)
    }

    function setColor(option) {
        if (AVAILABLE_COLOR_SCHEMES.includes(option)) {
            dispatch(colorSchemeActions.store(option))
            localStorage.setItem(PREFERRED_COLOR_SCHEME, option.value);
        }
    }


    return (
        <Collapse in={settings} collapsedSize={48}>
            <Box sx={groupStyle(0, settings)}>
                <ListItemButton sx={buttonStyle(0, settings)} onClick={handleSettings}>
                    <ListItemIconStyle><DisplaySettingsTwoToneIcon/></ListItemIconStyle>
                    <ListItemText disableTypography primary={localization.SETTINGS}/>
                </ListItemButton>

                <Stack spacing={1} sx={{p: 1}}>

                    {/*<Collapse in={language} collapsedSize={48}>
                        <Box sx={groupStyle(1, language)}>
                            <ListItemButton sx={buttonStyle(1, language)} onClick={handleLanguage}>
                                <ListItemIconStyle>
                                    <ListItemIconStyle>
                                        <PublicTwoToneIcon/>
                                    </ListItemIconStyle>
                                </ListItemIconStyle>
                                <ListItemText disableTypography primary={localization.LANGUAGE}/>
                            </ListItemButton>

                            <Stack spacing={1} sx={{p: 1}}>
                                {AVAILABLE_LANGUAGES.map(option => (
                                    <ListItemButton sx={buttonStyle(2, option.value === locale.value)}
                                                    key={option.value} onClick={() => setLanguageOption(option)}>
                                        <ListItemIconStyle>
                                            <ListItemIconStyle>
                                                <Box component="img" alt={option.label} src={option.icon} sx={{width: 28}}/>
                                            </ListItemIconStyle>
                                        </ListItemIconStyle>
                                        <ListItemText disableTypography primary={option.label}/>
                                    </ListItemButton>
                                ))}
                            </Stack>
                        </Box>
                    </Collapse>*/}

                    <Collapse in={darkMode} collapsedSize={48}>
                        <Box sx={groupStyle(1, darkMode)}>
                            <ListItemButton sx={buttonStyle(1, darkMode)} onClick={handleDarkMode}>
                                <ListItemIconStyle>
                                    <ListItemIconStyle>
                                        <DarkModeTwoToneIcon/>
                                    </ListItemIconStyle>
                                </ListItemIconStyle>
                                <ListItemText disableTypography primary={localization.DARK_MODE}/>
                            </ListItemButton>


                            <Stack spacing={1} sx={{p: 1}}>
                                {AVAILABLE_MODES.map(option => (
                                    <ListItemButton sx={buttonStyle(2, option.value === mode.value)}
                                                    key={option.value} onClick={() => setMode(option)}>
                                        <ListItemIconStyle>
                                            <ListItemIconStyle>
                                                {getModeIcon(option)}
                                            </ListItemIconStyle>
                                        </ListItemIconStyle>
                                        <ListItemText disableTypography primary={localization[option.value]}/>
                                    </ListItemButton>
                                ))}
                            </Stack>
                        </Box>
                    </Collapse>

                    <Collapse in={colorScheme} collapsedSize={48}>
                        <Box sx={groupStyle(1, colorScheme)}>
                            <ListItemButton sx={buttonStyle(1, colorScheme)} onClick={handleColorScheme}>
                                <ListItemIconStyle>
                                    <ListItemIconStyle>
                                        <ColorLensTwoToneIcon/>
                                    </ListItemIconStyle>
                                </ListItemIconStyle>
                                <ListItemText disableTypography primary={localization.COLOR_SCHEME}/>
                            </ListItemButton>


                            <Box sx={{p: 2}}>
                                <Grid container>
                                    {AVAILABLE_COLOR_SCHEMES.map(option => (
                                        <Box key={option.value} item xs={2}>
                                            <IconButton aria-label="delete" color="primary" onClick={() => setColor(option)}>
                                                <Box sx={{height: 43.5, width: 43.5, borderRadius: 15, backgroundColor: option.primary.light, '&:hover': {backgroundColor: option.primary.main}}}>
                                                    {option.value === color.value ? <CheckIcon sx={{color: option.primary.dark, fontSize: 43.5, p: 1}}/> : null}
                                                </Box>
                                            </IconButton>
                                        </Box>
                                    ))}
                                </Grid>

                            </Box>
                        </Box>
                    </Collapse>
                </Stack>

            </Box>
        </Collapse>
    );
}
