import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import CardGiftcardTwoToneIcon from "@mui/icons-material/CardGiftcardTwoTone";

const icons = {
    AccountCircleTwoToneIcon,
    CardGiftcardTwoToneIcon
};

const userPages = {
    id: 'user',
    type: 'group',
    auth: false,
    logged: true,
    admin: false,
    children: [
        {
            id: 'VOUCHER_LIST',
            type: 'item',
            url: '/voucher-list',
            icon: icons.CardGiftcardTwoToneIcon,
        }
        /*
        {
            id: 'PROFILE',
            type: 'item',
            url: '/profile',
            icon: icons.AccountCircleTwoToneIcon
        }
        */
    ]
};

export default userPages;