import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

function AdminRoute({children}) {

    const user = useSelector(state => state.auth.user);
    let navigate = useNavigate();

    useEffect(() => {
        if (!user) {
            navigate("/signin");
        }
        if (user.role !== 'ADMIN') {
            navigate("/")
        }
    }, [])

    return children;
}

export default AdminRoute;