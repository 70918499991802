import React, {Fragment, lazy, Suspense, useEffect} from 'react';
import {Route, Routes} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {AppBar, Backdrop, Box, CircularProgress, IconButton, Toolbar, Typography} from "@mui/material";
import * as PropTypes from "prop-types";
import UserRoute from "../common/UserRoute";
import AdminRoute from "../common/AdminRoute";
import Sidebar from "../common/sidebar/Sidebar";
import {ACCESS_TOKEN, PREFERRED_COLOR_SCHEME, PREFERRED_LANGUAGE, PREFERRED_MODE} from "../constants";
import {localizationActions} from "../store/settings/localization-slice";
import {darkModeActions} from "../store/settings/dark-mode-slice";
import {AVAILABLE_LANGUAGES} from "../constants/localization/languages";
import {AVAILABLE_MODES} from "../constants/settings/modes";
import {AVAILABLE_COLOR_SCHEMES} from "../constants/settings/colorSchemes";
import {colorSchemeActions} from "../store/settings/color-scheme-slice";
import {useSession} from "../util/Session";
import Center from "../components/Center";
import './main.scss'
import MobileSidebar from "../common/sidebar/MobileSidebar";
import HomepageRedirect from "../common/HomepageRedirect";

const Voucher = lazy(() => import('../pages/public/voucher/Voucher'));
const VoucherList = lazy(() => import('../pages/user/voucher/VoucherList'));
const VoucherOptions = lazy(() => import('../pages/admin/voucher/VoucherOptions'));
const VoucherStatistics = lazy(() => import('../pages/admin/voucher/VoucherStatistics'));
const SetSchedule = lazy(() => import('../pages/admin/SetSchedule'));
const BuyVoucher = lazy(() => import('../pages/public/voucher/BuyVoucher'));
const UserList = lazy(() => import('../pages/admin/users/UserList'));
const Login = lazy(() => import('../pages/auth/login/Login'));
const Signup = lazy(() => import('../pages/auth/signup/Signup'));
const OAuth2RedirectHandler = lazy(() => import('../pages/auth/oauth2/OAuth2RedirectHandler'));
const NotFound = lazy(() => import('../common/NotFound'));
const PasswordChange = lazy(() => import('../pages/auth/PasswordChange/PasswordChange'));
const ForgottenPassword = lazy(() => import('../pages/auth/PasswordChange/ForgottenPassword'));
const Profile = lazy(() => import('../pages/user/profile/Profile'));


UserRoute.propTypes = {
    user: PropTypes.any,
    children: PropTypes.node
};
export default function App() {

    const scheme = useSelector(state => state.colorScheme.scheme);
    const locale = useSelector(state => state.localization.locale);
    const mode = useSelector(state => state.darkMode.mode);
    const user = useSelector(state => state.auth.user);

    const dispatch = useDispatch();
    const { loadingSession, createSession } = useSession();

    if (localStorage.getItem(ACCESS_TOKEN) && !user && !loadingSession) {
        createAppSession();
    }

    async function createAppSession() {
        await createSession();
    }

    useEffect(() => {

        if (localStorage.getItem(PREFERRED_MODE) === null ) {
            localStorage.setItem(PREFERRED_MODE, mode.value);
        }

        if (localStorage.getItem(PREFERRED_LANGUAGE) === null) {
            localStorage.setItem(PREFERRED_LANGUAGE, locale.value);
        }

        if (localStorage.getItem(PREFERRED_COLOR_SCHEME) === null) {
            localStorage.setItem(PREFERRED_COLOR_SCHEME, scheme.value);
        }

        AVAILABLE_MODES.forEach(mode => {
            if (mode.value === localStorage.getItem(PREFERRED_MODE)) {
                if (locale.value !== mode.value) {
                    dispatch(darkModeActions.store(mode));
                }
            }
        });

        AVAILABLE_LANGUAGES.forEach(lang => {
            if (lang.value === localStorage.getItem(PREFERRED_LANGUAGE)) {
                if (locale.value !== lang.value) {
                    dispatch(localizationActions.store(lang));
                }
            }
        });

        AVAILABLE_COLOR_SCHEMES.forEach(color => {
            if (color.value === localStorage.getItem(PREFERRED_COLOR_SCHEME)) {
                if (scheme.value !== color.value) {
                    dispatch(colorSchemeActions.store(color));
                }
            }
        })
    }, [])

    if (loadingSession) {
        return (
            <Backdrop open={true} sx={{zIndex: 99}}>
                <CircularProgress color="primary"/>
            </Backdrop>
        )
    }


    return (
        <Fragment>
            <MobileSidebar />
            <Box className="layout">
                <Sidebar/>
                <Suspense fallback={<Center><CircularProgress color="primary"/></Center>}>
                    <Routes>
                        <Route index element={<BuyVoucher/>} />
                        <Route path="/reset-password" element={<PasswordChange/>}/>
                        <Route path="/forgotten-password" element={<ForgottenPassword/>} />
                        <Route path="/signin" element={<Login/>} />
                        <Route path="/oauth2/redirect" element={<OAuth2RedirectHandler/>} />
                        <Route path="/signup" element={<Signup/>} />
                        <Route path="/check-validity" element={<Voucher/>}/>
                        <Route path="/voucher-list" element={<UserRoute><VoucherList/></UserRoute>} />
                        <Route path="/user-list" element={<AdminRoute><UserList/></AdminRoute>} />
                        <Route path="/voucher-options" element={<AdminRoute><VoucherOptions/></AdminRoute>} />
                        <Route path="/voucher-statistics" element={<AdminRoute><VoucherStatistics/></AdminRoute>} />
                        <Route path="/set-schedule" element={<AdminRoute><SetSchedule/></AdminRoute>} />
                        <Route path="/index.html" element={<HomepageRedirect/>} />
                        <Route path="*" element={<NotFound/>}/>
                    </Routes>
                </Suspense>
            </Box>
        </Fragment>
    )
}
