import React from 'react';
import {Box, Button, IconButton, Typography} from '@mui/material';
import MenuList from './menu-list/MenuList';
import Profile from "./Profile";
import Footer from "./Footer";
import Settings from "./Settings";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Logo from "./Logo";
import {useTheme} from "@mui/material/styles";

export default function SidebarContent(props) {

    const theme = useTheme();

    return (
        <Box className="sidebar">
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Logo width="168" height="96"/>
                <Typography variant="h4" noWrap sx={{color: theme.palette.text.primary}}>
                    Motokáry Martin
                </Typography>
            </Box>
            <Profile />
            <Box sx={{flexGrow: 1}}>
                <MenuList />
                <Box sx={{py: 1}}>
                    <Settings />
                </Box>
            </Box>
            <Box sx={{display: {xs: 'flex', md: 'none'}, alignItems: 'stretch', flexDirection: 'column', my: 2}}>
                <Button color="primary"
                        size="large"
                        startIcon={<ArrowBackIosNewIcon />}
                        onClick={props.handleDrawerToggle}>
                    Zavrieť menu
                </Button>
            </Box>
            <Footer />
        </Box>
    );
}
