export const API_BASE_URL = 'https://api.motokarymartin.sk';
export const ACCESS_TOKEN = 'accessToken';
export const PREFERRED_MODE = 'preferredMode';
export const PREFERRED_LANGUAGE = 'preferredLanguage';
export const PREFERRED_COLOR_SCHEME = 'preferredColorScheme';

export const OAUTH2_REDIRECT_URI = 'https://eshop.motokarymartin.sk/oauth2/redirect'
export const GOOGLE_AUTH_URL = API_BASE_URL + '/oauth2/authorize/google?redirect_uri=' + OAUTH2_REDIRECT_URI;
export const FACEBOOK_AUTH_URL = API_BASE_URL + '/oauth2/authorize/facebook?redirect_uri=' + OAUTH2_REDIRECT_URI;
export const GITHUB_AUTH_URL = API_BASE_URL + '/oauth2/authorize/github?redirect_uri=' + OAUTH2_REDIRECT_URI;
