import {createSlice} from '@reduxjs/toolkit';
import {COLOR_SCHEME} from "../../constants/settings/colorSchemes";

const colorScheme = createSlice({
    name: 'colorScheme',
    initialState: { scheme: COLOR_SCHEME.YELLOW },
    reducers: {
        store(state, data) {
            state.scheme = data.payload;
        }
    }
});

export const colorSchemeActions = colorScheme.actions;
export default colorScheme;