import AdminPanelSettingsTwoToneIcon from '@mui/icons-material/AdminPanelSettingsTwoTone';
import ScheduleTwoToneIcon from '@mui/icons-material/ScheduleTwoTone';
import MonetizationOnTwoToneIcon from '@mui/icons-material/MonetizationOnTwoTone';
import BookmarkTwoToneIcon from '@mui/icons-material/BookmarkTwoTone';
import BookmarksTwoToneIcon from '@mui/icons-material/BookmarksTwoTone';
import LocalOfferTwoToneIcon from '@mui/icons-material/LocalOfferTwoTone';
import TableRowsTwoToneIcon from '@mui/icons-material/TableRowsTwoTone';
import CardGiftcardTwoToneIcon from '@mui/icons-material/CardGiftcardTwoTone';
import TuneIcon from '@mui/icons-material/Tune';
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone';
import AssessmentTwoToneIcon from '@mui/icons-material/AssessmentTwoTone';

const icons = {
    AdminPanelSettingsTwoToneIcon,
    ScheduleTwoToneIcon,
    MonetizationOnTwoToneIcon,
    BookmarkTwoToneIcon,
    BookmarksTwoToneIcon,
    LocalOfferTwoToneIcon,
    TableRowsTwoToneIcon,
    CardGiftcardTwoToneIcon,
    TuneIcon,
    PeopleAltTwoToneIcon,
    AssessmentTwoToneIcon
};

const adminPages = {
    id: 'admin',
    type: 'group',
    auth: false,
    logged: true,
    admin: true,
    children: [
        {
            id: 'ADMIN',
            type: 'group',
            icon: icons.AdminPanelSettingsTwoToneIcon,
            children: [
                {
                    id: 'VOUCHER_OPTIONS',
                    type: 'item',
                    url: '/voucher-options',
                    icon: icons.TuneIcon,
                },
                {
                    id: 'VOUCHER_STATISTICS',
                    type: 'item',
                    url: '/voucher-statistics',
                    icon: icons.AssessmentTwoToneIcon,
                },
                {
                    id: 'USER_LIST',
                    type: 'item',
                    url: '/user-list',
                    icon: icons.PeopleAltTwoToneIcon,
                },/*
                {
                    id: 'SET_SCHEDULE',
                    type: 'item',
                    url: '/set-schedule',
                    icon: icons.ScheduleTwoToneIcon,
                },
                {
                    id: 'PRICE_LIST',
                    type: 'group',
                    icon: icons.MonetizationOnTwoToneIcon,
                    children: [
                        {
                            id: 'CATEGORY',
                            type: 'item',
                            url: '/set-category',
                            icon: icons.BookmarkTwoToneIcon,
                        },
                        {
                            id: 'SUBCATEGORY',
                            type: 'item',
                            url: '/set-subcategory',
                            icon: icons.BookmarksTwoToneIcon,
                        },
                        {
                            id: 'ARTICLE',
                            type: 'item',
                            url: '/set-article',
                            icon: icons.LocalOfferTwoToneIcon,
                        },
                        {
                            id: 'PRICE_TABLE',
                            type: 'item',
                            url: '/set-price-table',
                            icon: icons.TableRowsTwoToneIcon,
                        },

                    ]
                }*/
            ]
        }
    ]
};

export default adminPages;