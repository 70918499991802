import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import registerServiceWorker from './registerServiceWorker';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from "react-redux";
import store from "./store";
import Theme from "./common/Theme";

ReactDOM.render(
    <BrowserRouter>
        <Provider store={store}>
            <Theme>
                <App/>
            </Theme>
        </Provider>
    </BrowserRouter>,
    document.getElementById('root')
);

registerServiceWorker();
