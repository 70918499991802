import React from 'react';
import {Paper} from '@mui/material';
import {useTheme} from "@mui/material/styles";
import SidebarContent from "./SidebarContent";

export default function   Sidebar() {

    const theme = useTheme();

    return (
        <Paper elevation={1} sx={{ display: {xs: 'none', md: 'block'}, borderRadius: theme.shape.borderRadius}} className="sidebar-wrapper">
            <SidebarContent />
        </Paper>
    );
}
