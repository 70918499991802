import React, {useEffect, useState} from 'react';
import {Badge, Box, Chip, Collapse, ListItemButton, ListItemIcon, ListItemText, Stack} from '@mui/material';
import {alpha, useTheme, styled} from '@mui/material/styles';
import {NavLink, useLocation} from 'react-router-dom';
import {useSelector} from "react-redux";

export default function NavItem({item, level}) {

    const Icon = item.icon;
    const [open, setOpen] = useState(false);
    const [loaded, setLoaded] = useState(false);

    const ListItemIconStyle = styled(ListItemIcon)({
        width: 22,
        height: 22,
        color: 'inherit',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    });

    const localization = useSelector(state => state.localization.locale.locale);
    const shop = useSelector(state => state.shop);

    const {pathname} = useLocation();
    const match = (path) => (path ? pathname === path : false);
    const theme = useTheme();

    useEffect(() => {
        if (!loaded) {
            if (item.type === 'group') {
                item.children.forEach(child => {
                    if (match(child.url)) {
                        setOpen(true);
                        setLoaded(true)
                    }
                })
            } else {
                setOpen(match(item.url))
            }
        }
    })


    function buttonStyle(open) {
        if (open) {
            return {
                color: 'primary.main',
                fontWeight: 'fontWeightMedium',
                ...theme.typography.body2,
                height: 48,
                backgroundColor: alpha(theme.palette.primary.main, 0.10),
                borderRadius: theme.shape.borderRadius,
            }
        } else {
            return {
                ...theme.typography.body2,
                height: 48,
                color: theme.palette.text.secondary,
                borderRadius: theme.shape.borderRadius,
            }
        }
    }

    function groupStyle(level, open) {
        if (open) {
            return {
                backgroundColor: level === 0 ? alpha(theme.palette.primary.main, 0.10) : (level === 1 ? alpha(theme.palette.primary.main, 0.05) : alpha(theme.palette.primary.main, 0.025)),
                borderRadius: theme.shape.borderRadius
            }
        } else {
            return {}
        }
    }

    function handleOpen() {
        setOpen(!open);
    }

    return (
        (item.type === 'group')
            ? (
                <Collapse in={open} collapsedSize={48}>
                    <Box sx={groupStyle(level, open)}>
                        <ListItemButton sx={buttonStyle(open)} onClick={handleOpen}>
                            <ListItemIconStyle><Icon/></ListItemIconStyle>
                            <ListItemText disableTypography primary={localization[item.id]}/>
                        </ListItemButton>

                        <Stack spacing={1} sx={{p: 1}}>
                            {item.children.map(child => <NavItem key={child.id} item={child} level={level++}/>)}
                        </Stack>
                    </Box>
                </Collapse>
            )
            : (
                <Box sx={groupStyle(level, open)}>
                    <ListItemButton component={NavLink} sx={buttonStyle(open)} to={item.url}>
                        <ListItemIconStyle><Icon/></ListItemIconStyle>
                        <ListItemText disableTypography primary={localization[item.id]}/>
                        { item.id === 'ESHOP' && shop.selected && <Chip color="primary" label="1"/>}
                    </ListItemButton>
                </Box>
            )

    );
}
