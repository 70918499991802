import {useCallback, useState} from "react";
import {useSnackbar} from "notistack";
import {authActions} from "../store/auth-slice";
import {useAPI} from "./API";
import {useDispatch, useSelector} from "react-redux";
import {shopActions} from "../store/public/shop-slice";

export const useSession = () => {

    const localization = useSelector(state => state.localization.locale.locale);

    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();
    const { handleGet } = useAPI();

    const [loadingSession, setLoadingSession] = useState(false);

    const createSession = useCallback(async () => {

        setLoadingSession(true);

        let endpoint = "/user/me"
        let data = await handleGet(endpoint);

        if (!data) {
            setLoadingSession(false);
            return;
        }

        dispatch(authActions.login(data));
        dispatch(shopActions.setEmail(data.email));
        enqueueSnackbar(localization.SIGN_IN_SUCCESSFUL, {variant: 'success', preventDuplicate: false});
        setLoadingSession(false);
    }, []);

    return {loadingSession, createSession};
}