import sk from "./sk-SK";
import en from "./en-US";
import skDate from 'date-fns/locale/sk';
import enDate from 'date-fns/locale/en-US';

export const DATE_LANGUAGES = {
    en: enDate,
    sk: skDate,
}

export const LANGUAGE = {
    ENGLISH: {
        id: 'en',
        value: 'en-US',
        locale: en,
        label: 'English',
        icon: '/flags/united-states.svg',
    },
    SLOVAK: {
        id: 'sk',
        value: 'sk-SK',
        locale: sk,
        label: 'Slovensky',
        icon: '/flags/slovakia.svg',
    }
};
export const AVAILABLE_LANGUAGES = [LANGUAGE.SLOVAK, LANGUAGE.ENGLISH];