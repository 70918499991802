import {createSlice} from '@reduxjs/toolkit';

const scheduleSlice = createSlice({
    name: 'schedule',
    initialState: { schedule: null },
    reducers: {
        store(state, data) {
            state.schedule = data.payload;
        },
    }
});

export const scheduleActions = scheduleSlice.actions;
export default scheduleSlice;