import {createSlice} from '@reduxjs/toolkit';

const authSlice = createSlice({
    name: 'auth',
    initialState: { user: null },
    reducers: {
        login(state, data) {
            state.user = data.payload;
        },
        logout(state) {
            state.user = null;
        },
    }
});

export const authActions = authSlice.actions;
export default authSlice;