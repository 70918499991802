import VpnKeyTwoToneIcon from '@mui/icons-material/VpnKeyTwoTone';
import PersonAddAltTwoToneIcon from '@mui/icons-material/PersonAddAltTwoTone';

const icons = {
    VpnKeyTwoToneIcon,
    PersonAddAltTwoToneIcon
};

const authPages = {
    id: 'auth',
    type: 'group',
    auth: true,
    logged: false,
    admin: false,
    children: [
        {
            id: 'SIGN_IN',
            title: 'Prihlásiť sa',
            type: 'item',
            url: '/signin',
            icon: icons.VpnKeyTwoToneIcon
        },
        {
            id: 'SIGN_UP',
            title: 'Vytvoriť účet',
            type: 'item',
            url: '/signup',
            icon: icons.PersonAddAltTwoToneIcon
        }
    ]
};

export default authPages;