import React from 'react';
import {Box, Divider, List, Stack} from '@mui/material';
import NavItem from './NavItems';
import {useSelector} from "react-redux";

export default function NavGroup({item}) {

    const user = useSelector(state => state.auth.user);
    const logged = !!user;
    const admin = logged && user.role === 'ADMIN';

    if (item.auth && logged) {
        return null;
    }

    if (item.logged && !logged) {
        return null;
    }

    if (item.admin && !admin) {
        return null;
    }

    return (
        <Box>
            <List>
                <Stack spacing={1}>
                    {item.children.map((menu) => <NavItem key={menu.id} item={menu} level={0}/>)}
                </Stack>
            </List>
            <Divider />
        </Box>
    );
}
