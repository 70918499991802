export const COLOR_SCHEME = {
    YELLOW: {
        value: 'YELLOW',
        primary: {
            light: '#ffe54c',
            main: '#ffb300',
            dark: '#c68400',
            contrastText: '#000000',
            complementary: '#BD4802'
        },
    },
    DARK_BLUE: {
        value: 'DARK_BLUE',
        primary: {
            light: '#58a5f0',
            main: '#0277bd',
            dark: '#004c8c',
            contrastText: '#ffffff',
            complementary: '#77BD02'
        },
    },
    LIGHT_BLUE: {
        value: 'LIGHT_BLUE',
        primary: {
            light: '#8bf6ff',
            main: '#4fc3f7',
            dark: '#0093c4',
            contrastText: '#000000',
            complementary: '#F74FC3'
        }
    },
    RED: {
        value: 'RED',
        primary: {
            light: '#ff7543',
            main: '#d84315',
            dark: '#9f0000',
            contrastText: '#000000',
            complementary: '#4315D8'
        },
    },
    ORANGE: {
        value: 'ORANGE',
        primary: {
            light: '#ffc046',
            main: '#ff8f00',
            dark: '#c56000',
            contrastText: '#000000',
            complementary: '#8F00FF'
        },
    },
    DARK_GREEN: {
        value: 'DARK_GREEN',
        primary: {
            light: '#85bb5c',
            main: '#558b2f',
            dark: '#255d00',
            contrastText: '#ffffff',
            complementary: '#2F558B'
        },
    },
    LIGHT_GREEN: {
        value: 'LIGHT_GREEN',
        primary: {
            light: '#e1ffb1',
            main: '#aed581',
            dark: '#7da453',
            contrastText: '#000000',
            complementary: '#81AED5'
        },
    },
    VIOLET: {
        value: 'VIOLET',
        primary: {
            light: '#c7a4ff',
            main: '#9575cd',
            dark: '#65499c',
            contrastText: '#000000',
            complementary: '#75CD95'
        },
    },
    BROWN: {
        value: 'BROWN',
        primary: {
            light: '#a98274',
            main: '#795548',
            dark: '#4b2c20',
            contrastText: '#ffffff',
            complementary: '#487955'
        },
    },
    GREY: {
        value: 'GREY',
        primary: {
            light: '#8eacbb',
            main: '#607d8b',
            dark: '#34515e',
            contrastText: '#ffffff',
            complementary: '#8B607D'
        },
    }
};
export const AVAILABLE_COLOR_SCHEMES = [COLOR_SCHEME.YELLOW, COLOR_SCHEME.ORANGE, COLOR_SCHEME.RED, COLOR_SCHEME.VIOLET, COLOR_SCHEME.LIGHT_GREEN, COLOR_SCHEME.DARK_GREEN, COLOR_SCHEME.LIGHT_BLUE, COLOR_SCHEME.DARK_BLUE, COLOR_SCHEME.BROWN, COLOR_SCHEME.GREY];