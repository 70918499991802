import publicPages from './public';
import userPages from "./user";
import adminPages from "./admin";
import authPages from "./auth";

const menuItems = {
    items: [authPages, publicPages, userPages, adminPages]
};

export default menuItems;
