import CottageTwoToneIcon from '@mui/icons-material/CottageTwoTone';
import ContactPhoneTwoToneIcon from '@mui/icons-material/ContactPhoneTwoTone';
import MonetizationOnTwoToneIcon from '@mui/icons-material/MonetizationOnTwoTone';
import PhotoCameraTwoToneIcon from '@mui/icons-material/PhotoCameraTwoTone';
import InstagramIcon from '@mui/icons-material/Instagram';
import TagIcon from '@mui/icons-material/Tag';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import LocalGroceryStoreTwoToneIcon from '@mui/icons-material/LocalGroceryStoreTwoTone';

const icons = {
    CottageTwoToneIcon,
    ContactPhoneTwoToneIcon,
    MonetizationOnTwoToneIcon,
    PhotoCameraTwoToneIcon,
    InstagramIcon,
    TagIcon,
    CheckCircleTwoToneIcon,
    LocalGroceryStoreTwoToneIcon
};

const publicPages = {
    id: 'public',
    type: 'group',
    auth: false,
    logged: false,
    admin: false,
    children: [
        {
            id: 'ESHOP',
            type: 'item',
            url: '/',
            icon: icons.LocalGroceryStoreTwoToneIcon
        },
        {
            id: 'CHECK_VALIDITY',
            type: 'item',
            url: '/check-validity',
            icon: icons.CheckCircleTwoToneIcon
        },/*
        {
            id: 'CONTACT',
            type: 'item',
            url: '/contact',
            icon: icons.ContactPhoneTwoToneIcon
        },
        {
            id: 'PRICE_LIST',
            type: 'item',
            url: '/price-list',
            icon: icons.MonetizationOnTwoToneIcon
        },
        {
            id: 'GALLERY',
            type: 'group',
            icon: icons.PhotoCameraTwoToneIcon,
            children: [
                {
                    id: 'INSTAGRAM_MEDIA',
                    type: 'item',
                    url: '/instagram-media',
                    icon: icons.InstagramIcon
                },
                {
                    id: 'INSTAGRAM_TOP',
                    type: 'item',
                    url: '/instagram-top',
                    icon: icons.TagIcon
                },
            ]
        }*/
    ]
};

export default publicPages;