import React, {Fragment, useState} from 'react';
import {AppBar, Box, Drawer, IconButton,  Toolbar, Typography} from '@mui/material';
import {useTheme} from "@mui/material/styles";
import MenuIcon from '@mui/icons-material/Menu';
import Logo from "./Logo";
import SidebarContent from "./SidebarContent";

export default function MobileSidebar() {

    const theme = useTheme();

    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const container = window !== undefined ? () => window.document.body : undefined;

    return (
        <Fragment>
            <AppBar sx={{display: {xs: 'block', md: 'none'}, backgroundColor: theme.palette.customGrey.dark, backgroundImage: 'none'}} className="appbar">
                <Toolbar>
                    <IconButton
                        color="primary"
                        aria-label="open menu"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2 }}>
                        <MenuIcon />
                    </IconButton>
                    <Box sx={{display: 'flex', justifyContent: 'center'}}>
                        <Logo width="60" height="36"/>
                    </Box>
                    <Typography variant="h6" noWrap sx={{ml: 2, color: theme.palette.text.primary}}>
                        Motokáry Martin
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}>
                <Box className="sidebar-mobile-wrapper">
                    <SidebarContent handleDrawerToggle={handleDrawerToggle} />
                </Box>
            </Drawer>
        </Fragment>
    );
}
