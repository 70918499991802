import DarkModeTwoToneIcon from '@mui/icons-material/DarkModeTwoTone';
import LightModeTwoToneIcon from '@mui/icons-material/LightModeTwoTone';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';

export const MODE_ICONS = {
    darkModeIcon: DarkModeTwoToneIcon,
    lightModeIcon: LightModeTwoToneIcon,
    systemIcon: SettingsSuggestIcon
};

export const MODE = {
    SYSTEM_PREF: {
        value: 'SYSTEM_PREF',
        auto: true,
        icon: 'systemIcon',
    },
    DARK: {
        value: 'DARK_MODE',
        theme: 'dark',
        auto: false,
        dark: true,
        icon: 'darkModeIcon',
    },
    LIGHT: {
        value: 'LIGHT_MODE',
        theme: 'light',
        auto: false,
        dark: false,
        icon: 'lightModeIcon',
    }
};
export const AVAILABLE_MODES = [MODE.SYSTEM_PREF, MODE.DARK, MODE.LIGHT];