import {createSlice} from '@reduxjs/toolkit';

const gallerySlice = createSlice({
    name: 'gallery',
    initialState: {
        gallery: {
            media: null,
            top: null,
        }
    },
    reducers: {
        storeMedia(state, data) {
            state.gallery.media = data.payload;
        },
        storeTop(state, data) {
            state.gallery.top = data.payload;
        },
    }
});

export const galleryActions = gallerySlice.actions;
export default gallerySlice;