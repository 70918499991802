import React from 'react';
import {SnackbarProvider, closeSnackbar} from "notistack";
import {ThemeProvider, CssBaseline, createTheme, useMediaQuery, IconButton} from "@mui/material";
import {useSelector} from "react-redux";
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import {DATE_LANGUAGES} from "../constants/localization/languages";
import CloseIcon from '@mui/icons-material/Close';

export default function Theme(props) {

    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const mode = useSelector(state => state.darkMode.mode);
    const scheme = useSelector(state => state.colorScheme.scheme);
    const localization = useSelector(state => state.localization.locale);
    const theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    mode: mode.auto ? (prefersDarkMode ? 'dark' : 'light') : mode.theme,
                    primary: scheme.primary,
                    secondary: {
                        light: '#ffffff',
                        main: mode.auto ? (prefersDarkMode ? '#ffffff' : '#000000') : mode.dark ? '#ffffff' : '#000000',
                        dark: '#000000',
                        contrastText: mode.auto ? (prefersDarkMode ? '#000000' : '#ffffff') : mode.dark ? '#000000' : '#ffffff',
                    },
                    background: {
                        default: mode.auto ? (prefersDarkMode ? '#121212' : '#f2f4f9') : mode.dark ? '#121212' : '#f2f4f9',
                    },
                    customGrey: {
                        light: mode.auto ? (prefersDarkMode ? '#282828' : '#f5f5f5') : mode.dark ? '#282828' : '#f5f5f5',
                        main: mode.auto ? (prefersDarkMode ? '#282828' : '#f5f5f5') : mode.dark ? '#282828' : '#f5f5f5',
                        dark: mode.auto ? (prefersDarkMode ? '#0d0d0d' : '#ffffff') : mode.dark ? '#0d0d0d' : '#ffffff',
                    }
                },
            }),
        [prefersDarkMode, mode, scheme],
    );

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={DATE_LANGUAGES[localization.id]}>
            <SnackbarProvider maxSnack={1}
                              autoHideDuration={5000}
                              anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                              action={(snackbarId) => (
                                  <IconButton aria-label="close" onClick={() => closeSnackbar(snackbarId)}>
                                      <CloseIcon />
                                  </IconButton>
                              )}>
                <CssBaseline/>
                <ThemeProvider theme={theme}>
                    <CssBaseline/>
                    {props.children}
                </ThemeProvider>
            </SnackbarProvider>
        </LocalizationProvider>
    )
}