import React, {Fragment} from 'react';
import {Box} from "@mui/material";

export default function Center(props) {

    return (
        <Box sx={{display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center'}}>
            <Box {...props}>
                {props.children}
            </Box>
        </Box>
    )

}
