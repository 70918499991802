import {createSlice} from '@reduxjs/toolkit';

const shopSlice = createSlice({
    name: 'shop',
    initialState: {
        step: 0,
        options: [],
        messages: [],
        selected: null,
        created: null,
        email: null,
    },
    reducers: {
        store(state, data) {
            state.options = data.payload;
        },
        storeMessages(state, data) {
            state.messages = data.payload;
        },
        select(state, data) {
            state.selected = data.payload;
        },
        create(state, data) {
            state.created = data.payload;
        },
        setEmail(state, data) {
            state.email = data.payload;
        },
        setStep(state, data) {
            state.step = data.payload;
        },
    }
});

export const shopActions = shopSlice.actions;
export default shopSlice;