import { API_BASE_URL, ACCESS_TOKEN } from '../constants';
import {useCallback, useState} from "react";
import {useSnackbar} from "notistack";

export const useAPI = () => {

    const [loading, setLoading] = useState(false);
    const [backendMessage, setBackendMessage] = useState(null);
    const {enqueueSnackbar} = useSnackbar();


    const handlePost = useCallback(async (endpoint, body) => {
        const options = {
            body: JSON.stringify(body),
            method: 'POST'
        };
        return await handleFetch(endpoint, options);
    }, []);

    const handleGet = useCallback(async endpoint => {
        const options = {
            method: 'GET'
        };
        return await handleFetch(endpoint, options);
    }, []);

    const handleDelete = useCallback(async endpoint => {
        const options = {
            method: 'DELETE'
        };
        return await handleFetch(endpoint, options);
    }, []);


    const handleFetch = async (endpoint, options) => {
        setLoading(true);
        setBackendMessage(null);

        const url = API_BASE_URL + endpoint;
        options.url = url;

        const headers = new Headers({'Content-Type': 'application/json',});
        if (localStorage.getItem(ACCESS_TOKEN)) {
            headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN));
        }
        options.headers = headers;

        try {
            const response = await fetch(options.url, options);
            const json = await response.json();

            if (response.status === 401 && localStorage.getItem(ACCESS_TOKEN)) {
                localStorage.removeItem(ACCESS_TOKEN);
            }

            if (json.hasOwnProperty("success")) {
                setBackendMessage({type: 'success', message: json.error});
                enqueueSnackbar(json.success, {variant: 'success', preventDuplicate: true});
            } else if (json.hasOwnProperty("info")) {
                setBackendMessage({type: 'info', message: json.error});
                enqueueSnackbar(json.info, {variant: 'info', preventDuplicate: true});
            } else if (json.hasOwnProperty("error")) {
                setBackendMessage({type: 'error', message: json.error});
                enqueueSnackbar(json.error, {variant: 'error', preventDuplicate: true});
            } else {
                return json;
            }
        } catch (error) {
            enqueueSnackbar(error, {variant: 'error'});
        } finally {
            setLoading(false);
        }
    };

    return {loading, backendMessage, handlePost, handleGet, handleDelete};
}