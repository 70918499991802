const en = {
    MONDAY: 'Monday',
    TUESDAY: 'Tuesday',
    WEDNESDAY: 'Wednesday',
    THURSDAY: 'Thursday',
    FRIDAY: 'Friday',
    SATURDAY: 'Saturday',
    SUNDAY: 'Sunday',
    SIGN_IN: 'Sign in',
    OR: 'OR',
    SIGN_UP: 'Create an account',
    NEW_USER: 'New user?',
    CONTINUE_WITH: 'Continue with ',
    APPLE: 'Apple',
    GOOGLE: 'Google',
    FACEBOOK: 'Facebook',
    GITHUB: 'GitHub',
    SIGN_IN_SUCCESSFUL: 'Sign in successful.',
    SING_UP_SUCCESSFUL: 'Sign up successful.',
    SIGN_OUT_SUCCESSFUL: 'Sign out successful.',
    BASIC_ERROR: 'Error! Something went wrong, please try again.',
    EMAIL: 'Email',
    PASSWORD: 'Password',
    HAVE_ACCOUNT: 'Already have an account?',
    FULL_NAME: 'Full name',
    OPENING_HOURS: 'Opening hours',
    VOUCHER: 'Gift voucher',
    IDENTITY: 'Identifier',
    CHECK_VALIDITY: 'Check validity',
    SETTINGS: 'Settings',
    ADMIN: 'Admin',
    USER: 'Racer',
    EMPLOYEE: 'Employee',
    HOMEPAGE: 'Home',
    CONTACT: 'Contact',
    PRICE_LIST: 'Price list',
    GALLERY: 'Photo gallery',
    PROFILE: 'Profile',
    ADMINISTRATOR: 'Administrator',
    RACER: 'Racer',
    LANGUAGE: 'Language',
    DARK_MODE: 'Dark mode',
    LIGHT_MODE: 'Light mode',
    SYSTEM_PREF: 'System preferences',
    COLOR_SCHEME: 'Color scheme',
    INSTAGRAM_MEDIA: 'Instagram',
    INSTAGRAM_TOP: 'Fan\'s posts',
    LIKE: 'Like',
    LIKES: 'Likes',
    SET_SCHEDULE: 'Opening hours',
    PREVIEW: 'Preview',
    CONTENT: 'Content',
    NEW_VALUES: 'New values',
    DAY: 'Day',
    OPEN_FROM: 'From',
    CLOSED_FROM: 'To',
    CLOSED: 'Closed',
    TABLE_ACTIONS: 'Actions',
    SAVE: 'Save',
    OPENING_HOURS_SUCCESSFUL_CHANGE: 'Opening hours were changed.',
    CATEGORY_TABLE: 'Category',
    CATEGORY: 'Categories',
    SUBCATEGORY: 'Subcategories',
    SUBCATEGORY_TABLE: 'Subcategory',
    ARTICLE: 'Articles',
    ARTICLE_TABLE: 'Article',
    PRICE_TABLE: 'Price table',
    ADD_PRICE_TABLE: 'Add new price table',
    ALL_CATEGORY: 'All categories',
    ALL_SUBCATEGORY: 'All subcategories',
    ALL_ARTICLES: 'All articles',
    ADD_CATEGORY: 'Add new categories',
    ADD_SUBCATEGORY: 'Add new subcategories',
    ADD_ARTICLES: 'Add new articles',
    ADD_ALERT: 'Add new alert',
    EDIT_ALERT: 'Edit existing alert',
    ICON: 'Icon',
    TITLE: 'Title',
    DESCRIPTION: 'Description',
    ALERTS: 'Alerts',
    SEVERITY: 'Severity',
    INFO: 'Info',
    ERROR: 'Error',
    WARNING: 'Warning',
    SUCCESS: 'Success',
    VARIANT: 'Varianta',
    OUTLINED: 'Outlined',
    FILLED: 'Filled',
    CLASSIC: 'Classic',
    MESSAGE: 'Message',
    CANCEL: 'Cancel',
    ADD: 'Add',
    EDIT: 'Edit',
    REQUIRED_FIELD: 'This field is required!',
    SELECT_ICON: 'Select new icon',
    SELECTED_ICON: 'Selected icon',
    CHANGE_ICON: 'Change icon',
    CHOOSE: 'Choose',
    ADD_CATEGORY_SUCCESSFUL: 'Category was created successfully.',
    CHANGE_CATEGORY_SUCCESSFUL: 'Category was changed successfully.',
    DELETE_CATEGORY_SUCCESSFUL: 'Category was deleted successfully.',
    ADD_SUBCATEGORY_SUCCESSFUL: 'Subcategory was created successfully.',
    CHANGE_SUBCATEGORY_SUCCESSFUL: 'Subcategory was changed successfully.',
    DELETE_SUBCATEGORY_SUCCESSFUL: 'Subcategory was deleted successfully.',
    ADD_ARTICLE_SUCCESSFUL: 'Article was created successfully.',
    CHANGE_ARTICLE_SUCCESSFUL: 'Article was changed successfully.',
    DELETE_ARTICLE_SUCCESSFUL: 'Article was deleted successfully.',
    VOLUME: 'Volume',
    PRICE: 'Price',
    ADD_NEW_ROW: 'Add row',
    REMOVE_COLUMN: 'Remove column',
    REMOVE_ROW: 'Remove row',
    ADD_NEW_COLUMN: 'Add column',
    EDIT_TITLE: 'Edit text',
    CONFIRM: 'Confirm',
    EVENT: 'Event',
    DATE_TIME: 'Date and time',
    USER_GENERAL: 'User',
    VOUCHER_CREATE: 'Gift voucher was created',
    VOUCHER_ACTIVATE: 'Payment was received and voucher was activated',
    VOUCHER_USE: 'Gift voucher was used',
    VOUCHER_REACHED_CAPACITY: 'Number of total rides was reached',
    VOUCHER_TERMINATED: 'Gift voucher was terminated',
    HISTORY: 'History',
    STATUS: 'Status',
    RIDES_TOTAL: 'Rides total',
    RIDES_USED: 'Rides used',
    PAYMENT_REQUIRED: 'Payment required',
    ACTIVE: 'Active',
    REACHED_CAPACITY: 'Reached capacity',
    TERMINATED: 'Terminated',
    VOUCHER_LIST: 'Voucher list',
    USER_LIST: 'User list',
    ACTIVATE: 'Activate',
    TERMINATE: 'Terminate',
    FILTER: 'Filter',
    FILTER_ACTION: 'Filter table',
    ESHOP: 'E-SHOP',
    CREATE_VOUCHER_OPTION: 'Create voucher option',
    CREATE: 'Create',
    VOUCHER_OPTIONS: 'Voucher settings',
    RIDES_NUMBER: 'Počet jázd',
    DELETE: 'Delete',
    WELCOME_POSTER: 'Give a gift full of adrenaline',
    WELCOME_POSTER_SUBTITLE: 'Are you looking for an original way to surprise your parents, delight your partner, or share smiles with your friends? We are here for you. Surprise your family, friends, and maybe even yourself by giving them unforgettable moments and experiences they will remember for a long time. Choose from a wide range of our gift vouchers and bring joy to the most important people in your life.',
    SELECT_VOUCHER_OPTION: 'Select voucher',
    FILL_INFO: 'Fill in contact details',
    COLLECT_VOUCHER: 'Collect your voucher',
    SINGLE_RIDE: 'ride',
    SMALL_AMOUNT_RIDES: 'rides',
    BIG_AMOUNT_RIDES: 'rides',

};

export default en;