import React from 'react';
import {Avatar, Box, Typography} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {useSnackbar} from "notistack";
import {authActions} from "../../store/auth-slice";
import {ACCESS_TOKEN} from "../../constants";
import IconButton from "@mui/material/IconButton";
import LogoutIcon from "@mui/icons-material/Logout";
import {useTheme} from "@mui/material/styles";
import {shopActions} from "../../store/public/shop-slice";

export default function Profile() {

    const {enqueueSnackbar} = useSnackbar();

    const scheme = useSelector(state => state.colorScheme.scheme);
    const localization = useSelector(state => state.localization.locale.locale);
    const user = useSelector(state => state.auth.user);
    const dispatch = useDispatch();
    const theme = useTheme();

    const logout = async () => {
        await dispatch(authActions.logout);
        await dispatch(shopActions.setEmail(null));
        localStorage.removeItem(ACCESS_TOKEN);
        enqueueSnackbar(localization.SIGN_OUT_SUCCESSFUL, {variant: 'success'});
    }

    if (user == null) {
        return null;
    }

    return (
        <Box sx={{display: 'flex', mt: 2, p: 2, borderRadius: theme.shape.borderRadius, backgroundColor: theme.palette.customGrey.main }}>
            <Avatar src={user.imageUrl} className={"background-gradient-" + scheme.value} alt="photoURL">
                {user.name[0]}
            </Avatar>
            <Box sx={{ml: 2, flexGrow: 1}}>
                <Typography variant="subtitle2" sx={{color: 'text.primary'}}>
                    {user.name}
                </Typography>
                <Typography variant="body2" sx={{color: 'text.secondary'}}>
                    {localization[user.role]}
                </Typography>
            </Box>
            <IconButton aria-label="logout" onClick={logout} href="/">
                <LogoutIcon />
            </IconButton>
        </Box>
    )
}