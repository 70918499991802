import {configureStore} from "@reduxjs/toolkit";
import authSlice from "./auth-slice";
import scheduleSlice from "./public/schedule-slice";
import localizationSlice from "./settings/localization-slice";
import darkMode from "./settings/dark-mode-slice";
import colorScheme from "./settings/color-scheme-slice";
import gallerySlice from "./public/gallery-slice";
import shopSlice from "./public/shop-slice";

const store = configureStore({
    reducer: {
        auth: authSlice.reducer,
        schedule: scheduleSlice.reducer,
        gallery: gallerySlice.reducer,
        shop: shopSlice.reducer,
        localization: localizationSlice.reducer,
        darkMode: darkMode.reducer,
        colorScheme: colorScheme.reducer
    }
});

export default store;