import React from 'react';
import {Box, Typography} from '@mui/material';

export default function Footer() {
    return (
        <Box sx={{textAlign: 'center'}}>
            <Typography variant="overline">
                © Motokáry Martin
            </Typography>
        </Box>
    );
}
