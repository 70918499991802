import React from 'react';
import {Navigate} from "react-router-dom";
import {useSelector} from "react-redux";

function UserRoute({children}) {

    const user = useSelector(state => state.auth.user);

    if (!user)
        return <Navigate to="/signin" />


    return children;
}

export default UserRoute;